import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { IconButton, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import TextField from "@mui/material/TextField";
import { Formik, Form, Field } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ButtonGroup from "@mui/material/ButtonGroup";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import NextPlanOutlinedIcon from '@mui/icons-material/NextPlanOutlined';

// import { Dayjs } from "dayjs";
import Alert from "@mui/material/Alert";
import axios from "../../lib/axios";
import dayjs from 'dayjs';

import OtherBookingPopup from "./OtherBookingPopup ";

export default function ScrollDialog({ profile, open, setOpen }) {
  const scroll = "paper";
  const today = dayjs();
  const [secondopen, setSecondopen] = React.useState(false);

  const type = "";

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [checkIn, setCheckIn] = useState(null);
  const [checkOut, setCheckOut] = useState(null);
  const sltitem = {};
  const [adults, setAdults] = useState("");
  const [childrens, setChildrens] = useState("");
  const [rooms, setRooms] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [isForm, setIsForm] = useState(false);

  // const handleClickOpen = (scrollType) => () => {
  //   setOpen(true);
  //   setScroll(scrollType);
  // };


  // const handleType = (type) => {
  //   setOpen(true);
  //   setType(type);
  // };

  const handleFormTrue = () => {
    setIsForm(true);
  };


  const handleClose = () => {
    setOpen(false);
  };

  const handleFormFalse = () => {
    setIsForm(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  let initialValues = {
    hotelId: "",
    bookingType: "",
    typeId: "",
    name: "",
    price: "",
    discount: "",
    checkingDate: "",
    checkoutDate: "",
    adults: "",
    childrens: "",
    numberOfRooms: "",
    userName: "",
    userEmail: "",
    contactNumber: "",
  };

  const submit = async (e) => {
    try {
      await axios.post("/booking/", {
        hotelId: profile?.hotelId,
        bookingType: profile?.packageType,
        typeId: profile?._id,
        name: profile?.name,
        price: profile?.minimumCost,
        discount: 1,
        checkingDate: new Date(checkIn) + 1,
        checkoutDate: new Date(checkOut) + 1,
        adults: adults,
        childrens: childrens,
        numberOfRooms: rooms,
        userName: userName,
        userEmail: userEmail,
        contactNumber: contactNumber,
      });

      setAlert({
        showAlert: true,
        severity: "success",
        message: "Hotel book successfully",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Hotel book successfully",
          }),
        5000
      );
      setTimeout(() => setSecondopen(true), 5000);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Server error!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Server error!",
          }),
        5000
      );
    }
  };
  const enabledButtonStyle = {
    width: "100%",
    borderRadius: "none",
    textTransform: "capitalize",
    color: "#ffffff",
    backgroundColor: "#34E0A1",
    fontFamily: "'Inter', sans-serif",
  };

  const disabledButtonStyle = {
    ...enabledButtonStyle,
    backgroundColor: "#CCCCCC", // Grayed out background color
    pointerEvents: "none", // Disable pointer events on the button
    cursor: "not-allowed", // Change the cursor to 'not-allowed'
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <div>
      {/* <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <Button
              variant="contained"
              {...bindTrigger(popupState)}
              style={{
                width: "100%",
                borderRadius: "none",
                textTransform: "capitalize",
                color: profile?.style?.offerbtntextColor || "#ffffff",
                backgroundColor:
                  profile?.style?.offerbtnbackgroundColor || "#003366",
                fontFamily: profile?.style?.fontFamily,
              }}
            >
              Book Now
              {show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Button>
            <Menu {...bindMenu(popupState)}>
              {profile.accommodation && (
                <MenuItem
                  onClick={() => {
                    handleType("accommodation");
                    setSltitem(profile?.accommodation[0]?);
                  }}
                >
                  Accommodation{" "}
                </MenuItem>
              )}
              {profile.offer && (
                <MenuItem
                  onClick={() => {
                    handleType("offers");
                    setSltitem(profile?.offer[0]?);
                  }}
                >
                  Offers
                </MenuItem>
              )}
              {profile.activity && (
                <MenuItem
                  onClick={() => {
                    handleType("activities");
                    setSltitem(profile?.activity[0]?);
                  }}
                >
                  Activities
                </MenuItem>
              )}
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
      <Button
        variant="contained"
        style={{
          width: "100%",
          borderRadius: "none",
          textTransform: "capitalize",
          color: profile?.style?.offerbtntextColor || "#ffffff",
          backgroundColor: profile?.style?.offerbtnbackgroundColor || "#003366",
          fontFamily: profile?.style?.fontFamily,
        }}
        onClick={handleClickOpen()}
      >
        {" "}
        Book Now
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{
          "& .css-ypiqx9-MuiDialogContent-root": { padding: 0 },
        }}
      >
        <DialogContent>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Grid
              container
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Grid
                item
                container
                xs={12}
                sx={{
                  justifyContent: "center",
                  alignContent: "center",
                  display: {
                    md: "none",
                    sm: "flex",
                    xs: "flex",
                  },
                }}
              >
                <Grid item sx={12}>
                  <ButtonGroup
                    disableElevation
                    variant="contained"
                    aria-label="Disabled elevation buttons"
                    sx={{
                      margin: "1.5rem 0",
                      padding: "0.5rem",
                      backgroundColor: "#EBEBEB",
                      borderRadius: 0,
                      border: "none",
                    }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "capitalize",
                        color: "#000",
                        "&:hover": {
                          backgroundColor: "#ffffff", // Change background color on hover
                        },
                        "&.MuiButtonBase-root": {
                          backgroundColor: "#ffffff",
                          borderRadius: 0,
                          border: "none",
                          marginRight: "0.7rem",
                          width: "10rem",
                        },
                      }}
                      onClick={() => {
                        handleFormFalse();
                      }}
                    >
                      <InsertPhotoOutlinedIcon
                        sx={{ paddingRight: "0.7rem" }}
                      />
                      View
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "capitalize",
                        color: "#000",
                        "&:hover": {
                          backgroundColor: "#ffffff", // Change background color on hover
                        },
                        "&.MuiButtonBase-root": {
                          backgroundColor: "#ffffff",
                          borderRadius: 0,
                          border: "none",
                          width: "10rem",
                        },
                      }}
                      onClick={() => {
                        handleFormTrue();
                      }}
                    >
                      <NextPlanOutlinedIcon
                        sx={{ paddingRight: "0.7rem" }}
                      />
                      Continue
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xl={8}
                lg={8}
                md={10}
                sm={11}
                xs={11}
                justifyContent={"space-between"}
                alignItems={"start"}
              >
                {sltitem ? (
                  <Grid
                    item
                    container
                    xl={8}
                    lg={8}
                    md={8}
                    sm={12}
                    xs={12}
                    sx={{
                      paddingRight: "10px",
                      display: {
                        xl: "block",
                        lg: "block",
                        md: "block",
                        sm: isForm ? "none" : "block",
                        xs: isForm ? "none" : "block",
                      },
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        fontFamily:
                          profile?.style?.fontFamily || "'Inter', sans-serif",
                        fontSize: "1rem",
                        fontWeight: "600",
                        margin: "1.5rem 0",
                        textTransform: "capitalize",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          window.location.reload();
                        }}
                      >
                        <ArrowBackIosIcon sx={{ fontSize: "1.2rem" }} />
                      </IconButton>{" "}
                      {profile?.packageType} Booking
                    </Typography>
                    <Grid xs={12}>
                      <Grid
                        container
                        item
                        xs={12}
                      >
                        <Grid
                          item
                          xs={6}
                          paddingRight={"0.4rem"}
                        >
                          {" "}
                          <img
                            src={sltitem?.image1 || profile?.image1}
                            alt="cardImage"
                            style={{
                              objectFit: "cover",
                              height: "25vh",
                              width: "100%",
                            }}
                          />
                        </Grid>{" "}
                        <Grid item xs={6}>
                          <img
                            src={sltitem?.image2 || profile?.image2}
                            alt="cardImage"
                            style={{
                              objectFit: "cover",
                              height: "25vh",
                              width: "100%",
                            }}
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          xs={6}
                          paddingRight={"0.4rem"}
                        >
                          <img
                            src={sltitem?.image3 || profile?.image3}
                            alt="cardImage"
                            style={{
                              objectFit: "cover",
                              height: "25vh",
                              width: "100%",
                            }}
                          />
                        </Grid>
                        <Grid container item xs={6}>
                          <img
                            src={sltitem?.image4 || profile?.image4}
                            alt="cardImage"
                            style={{
                              objectFit: "cover",
                              height: "25vh",
                              width: "100%",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid sx={{ padding: "1rem 0.7rem" }}>
                        <Typography
                          style={{
                            color: "#000",
                            fontFamily:
                              profile?.style?.fontFamily ||
                              "'Inter', sans-serif",
                            fontSize: "1.2rem",
                            fontWeight: "600",
                          }}
                        >
                          {sltitem?.name || profile?.name}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#303030",
                            fontFamily:
                              profile?.style?.fontFamily ||
                              "'Inter', sans-serif",
                            fontSize: "1rem",
                            fontWeight: "400",
                            paddingBottom: "1rem",
                            display: "flex",
                          }}
                        >
                          District:
                          <a
                            href={profile?.maplink}
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: "#303030", textDecoration: "none" }}
                          >
                            <Typography sx={{
                              fontWeight: 700, "&:hover": {
                                cursor: "pointer",
                                textDecoration: "underline",
                              },
                            }}>
                              {" "}
                              {sltitem?.district || profile?.district}
                            </Typography>
                          </a>
                        </Typography>
                        <Divider
                          sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
                        />
                        <Typography
                          style={{
                            color: "#000",
                            fontFamily:
                              profile?.style?.fontFamily ||
                              "'Inter', sans-serif",
                            fontSize: "1.2rem",
                            fontWeight: "600",
                          }}
                        >
                          What this place offers{" "}
                        </Typography>
                        <Grid
                          container
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          paddingBottom={"1rem"}
                        >
                          {profile?.facility.map((item) => (
                            <Grid
                              item
                              xs={4}
                              sx={{
                                color: "#222",
                                justifyContent: "center",
                                alignItems: "center",
                                fontFamily:
                                  profile?.style?.fontFamily ||
                                  "'Inter', sans-serif",
                                fontSize: "1rem",
                                fontWeight: "400",
                                paddingLeft: 0,
                              }}
                            >
                              {/* <img
                                src={item?.icon}
                                alt="cardImage"
                                style={{
                                  objectFit: "cover",
                                  height: "1rem",
                                  width: "1rem",
                                  paddingRight: "1rem",
                                }}
                              /> */}
                              <li>{item?.name}</li>
                            </Grid>
                          ))}
                        </Grid>
                        <Typography
                          style={{
                            color: "#757575",
                            fontFamily:
                              profile?.style?.fontFamily ||
                              "'Inter', sans-serif",
                            fontSize: "1rem",
                            fontWeight: "400",
                            marginTop: "1rem",
                          }}
                        >
                          <span
                            style={{
                              color: "#303030",
                              fontFamily:
                                profile?.style?.fontFamily ||
                                "'Inter', sans-serif",
                              fontSize: "1.2rem",
                              fontWeight: "700",
                            }}
                          >
                            LKR {sltitem?.minimumCost || profile?.minimumCost}
                          </span>{" "}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid xs={12}>
                      <iframe
                        title="map"
                        allowfullscreen
                        src={profile?.googleLocation}
                        style={{
                          width: "100%",
                          loading: "lazy",
                          border: 0,
                          referrerpolicy: "no-referrer-when-downgrade",
                          height: "20rem",
                          marginBottom: "5rem"
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    item
                    xl={7}
                    lg={7}
                    md={7}
                    sm={12}
                    xs={12}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      height: "25vh",
                      display: {
                        xl: "block",
                        lg: "block",
                        md: "block",
                        sm: !isForm ? "none" : "block",
                        xs: !isForm ? "none" : "block",
                      },
                    }}
                  >
                    <Grid
                      item
                      style={{
                        color: "#303030",
                        fontFamily:
                          profile?.style?.fontFamily || "'Inter', sans-serif",
                        fontSize: "1.2rem",
                        fontWeight: "700",
                        marginTop: "1.5rem",
                      }}
                    >
                      Item not selected!
                    </Grid>
                  </Grid>
                )}
                <Grid
                  item
                  container
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  sx={{
                    backgroundColor: "#F5F5F5",
                    padding: "0 0.7rem 1rem 0.7rem",
                    display: {
                      xl: "block",
                      lg: "block",
                      md: "block",
                      sm: !isForm ? "none" : "block",
                      xs: !isForm ? "none" : "block",
                    },
                  }}
                >
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        color: "#303030",
                        fontFamily:
                          profile?.style?.fontFamily || "'Inter', sans-serif",
                        fontSize: "1.2rem",
                        fontWeight: "700",
                        marginTop: "1.5rem",
                      }}
                    >
                      LKR {sltitem?.minimumCost || profile?.minimumCost}
                    </Typography>
                    <Grid item xs={12}>
                      <Formik
                        sx={{ width: "100%" }}
                        initialValues={initialValues}
                        onSubmit={submit}
                      // validationSchema={hotelBookSchema}
                      >
                        {({ dirty, isValid }) => {
                          return (
                            <Form>
                              <Grid container item xs={12} sm={12} md={12}>
                                <Grid
                                  item
                                  xs={12}
                                  width={"100%"}
                                  sx={{
                                    paddingBottom: "1.3rem",
                                    paddingTop: "1.3rem",
                                  }}
                                >
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      sx={{
                                        backgroundColor: "#ffffff",
                                        width: "100%",
                                        borderRadius: 0,
                                      }}
                                      label="Check In Date"
                                      value={checkIn}
                                      inputFormat="dd-MM-yyyy"
                                      onChange={(newValue) => {
                                        setCheckIn(newValue);
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      sx={{
                                        backgroundColor: "#ffffff",
                                        width: "100%",
                                        borderRadius: 0,
                                      }}
                                      label="Check Out Date"
                                      value={checkOut}
                                      minDate={checkIn || today}
                                      onChange={(newValue) => {
                                        setCheckOut(newValue);
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    paddingTop: "1.3rem",
                                    paddingBottom: "1.3rem",
                                  }}
                                >
                                  <Field
                                    name="adults"
                                    component={TextField}
                                    label="Adults"
                                    fullWidth
                                    type="number"
                                    min="1"
                                    onChange={(event) => {
                                      setAdults(event.target.value);
                                    }}
                                    required
                                    sx={{
                                      "& .MuiInputBase-input": {
                                        backgroundColor: "#ffffff",
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    paddingBottom: "1.3rem",
                                  }}
                                >
                                  <Field
                                    name="childrens"
                                    component={TextField}
                                    label="Childrens"
                                    fullWidth
                                    type="number"
                                    min="1"
                                    onChange={(event) => {
                                      setChildrens(event.target.value);
                                    }}
                                    required
                                    sx={{
                                      "& .MuiInputBase-input": {
                                        backgroundColor: "#ffffff",
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    paddingBottom: "1.3rem",
                                  }}
                                >
                                  <Field
                                    name="numberOfRooms"
                                    component={TextField}
                                    label="Rooms"
                                    fullWidth
                                    type="number"
                                    min="1"
                                    onChange={(event) => {
                                      setRooms(event.target.value);
                                    }}
                                    required
                                    sx={{
                                      "& .MuiInputBase-input": {
                                        backgroundColor: "#ffffff",
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Divider
                                    sx={{
                                      marginTop: "1.5rem",
                                      marginBottom: "1.5rem",
                                    }}
                                  />
                                </Grid>
                                <Typography
                                  style={{
                                    color: "#757575",
                                    fontFamily:
                                      profile?.style?.fontFamily ||
                                      "'Inter', sans-serif",
                                    fontSize: "1rem",
                                    fontWeight: "400",
                                  }}
                                >
                                  Enter your details
                                </Typography>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  style={{
                                    paddingBottom: "1.3rem",
                                    paddingTop: "1.3rem",
                                  }}
                                >
                                  <Field
                                    name="userName"
                                    component={TextField}
                                    label="User Name"
                                    fullWidth
                                    onChange={(event) => {
                                      setUserName(event.target.value);
                                    }}
                                    required
                                    sx={{
                                      "& .MuiInputBase-input": {
                                        backgroundColor: "#ffffff",
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  style={{
                                    paddingBottom: "1.3rem",
                                  }}
                                >
                                  <Field
                                    name="userEmail"
                                    component={TextField}
                                    label="Insert your Email"
                                    fullWidth
                                    required
                                    onChange={(event) => {
                                      setUserEmail(event.target.value);
                                      const email = validateEmail(event.target.value);
                                      if (email) {
                                        setEmailError(false)
                                      } else {
                                        setEmailError(true);
                                      }
                                    }}
                                    sx={{
                                      "& .MuiInputBase-input": {
                                        backgroundColor: "#ffffff",
                                      },
                                    }}
                                  />
                                  {emailError && <p>enter valid email!</p>}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  style={{
                                    paddingBottom: "1.3rem",
                                  }}
                                >
                                  <Field
                                    name="contactNumber"
                                    component={TextField}
                                    label="Telephone (mobile number preferred)"
                                    fullWidth
                                    required
                                    onChange={(event) => {
                                      setContactNumber(event.target.value);
                                    }}
                                    sx={{
                                      "& .MuiInputBase-input": {
                                        backgroundColor: "#ffffff",
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <Button
                                    variant="contained"
                                    disabled={
                                      !userName ||
                                      !userEmail ||
                                      !contactNumber ||
                                      !checkIn ||
                                      !checkOut ||
                                      !adults ||
                                      !childrens ||
                                      !sltitem ||
                                      !rooms
                                    }
                                    style={
                                      !userName ||
                                        !userEmail ||
                                        !contactNumber ||
                                        !checkIn ||
                                        !checkOut ||
                                        !adults ||
                                        !childrens ||
                                        !rooms
                                        ? disabledButtonStyle
                                        : enabledButtonStyle
                                    }
                                    onClick={() => {
                                      submit();
                                    }}
                                  >
                                    Confirm Booking
                                  </Button>
                                </Grid>
                              </Grid>
                            </Form>
                          );
                        }}
                      </Formik>
                      {alert.showAlert && (
                        <Grid item md={12} style={{ marginTop: "0.7rem" }}>
                          <Alert
                            severity={alert.severity}
                            onClose={() =>
                              setAlert({
                                ...alert,
                                showAlert: false,
                              })
                            }
                          >
                            {alert.message}
                          </Alert>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <OtherBookingPopup
              secondopen={secondopen}
              setSecondopen={setSecondopen}
              secondhandleClose={handleClose}
              sltitem={profile}
              userName={userName}
              userEmail={userEmail}
              contactNumber={contactNumber}
              type={type}
              adults={Number(adults) + Number(childrens)}
              checkIn={new Date(checkIn) + 1}
              checkOut={new Date(checkOut) + 1}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
