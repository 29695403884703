import React from "react";
import "./footer.css";
import { Grid, Typography } from "@mui/material";
// import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";


function Footer({handleClick}) {
  let navigate = useNavigate();

  const HOME = () => {
    navigate("/");
 };
 const AboutUs = () => {
  navigate("/about-us");
};
const Feedback = () => {
  navigate("/feedback");
};
const Terms = () => {
  navigate("/terms");
};

  return (
    <>
      <Grid
        container
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        justifyContent={"center"}
        sx={{ marginTop: "3rem" }}
      >
        <Grid
          item
          container
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            backgroundColor: "#F2F2F2",
            justifyContent: "center",
            padding: "1rem 0",
          }}
        >
          <Grid
            item
            container
            xs={10}
            sx={{
              padding: "2.5rem 0",
              backgroundColor: "#F2F2F2",
              alignContent: "center",
            }}
            justifyContent={{
              xl: "space-between",
              lg: "space-between",
              md: "start",
              sm: "start",
              xs: "start",
            }}
          >
            <Grid
              xl={2.5}
              lg={2.5}
              md={6}
              sm={6}
              xs={12}
              sx={{ height: "10rem" }}
            >
              <Typography
                lineHeight={"1.6rem"}
                textAlign={{
                  xl: "start",
                  lg: "start",
                  md: "start",
                  sm: "start",
                  xs: "center",
                }}
                sx={{
                  color: "#000",
                  fontSize: "1rem",
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: 500,
                }}
              >
                OUR ADDRESS
              </Typography>
              <Typography
                lineHeight={"1.6rem"}
                textAlign={{
                  xl: "start",
                  lg: "start",
                  md: "start",
                  sm: "start",
                  xs: "center",
                }}
                sx={{
                  color: "#000",
                  fontSize: "1rem",
                  fontFamily: "'Inter', sans-serif",
                  width: {
                    sm: "80%",
                    xs: "100%",
                  },
                }}
              >
                No 349/F, Batagama North, Ja-Ela Gampaha, Western, Sri Lanka.
              </Typography>
              <Typography
                lineHeight={"1.6rem"}
                textAlign={{
                  xl: "start",
                  lg: "start",
                  md: "start",
                  sm: "start",
                  xs: "center",
                }}
                sx={{
                  paddingTop:"1rem",
                  color: "#000",
                  fontSize: "1rem",
                  fontFamily: "'Inter', sans-serif",
                  width: {
                    sm: "80%",
                    xs: "100%",
                  },
                }}
              >
                mrtravelersinfo@gmail.com
              </Typography>
            </Grid>
            <Grid
              container
              xl={2.5}
              lg={2.5}
              md={6}
              sm={6}
              xs={12}
              sx={{
                justifyContent: "space-between",
                flexDirection: "column",
                textAlign: "center",
                height: "10rem",
              }}
            >
              <Typography
                lineHeight={"1.6rem"}
                textAlign={{
                  xl: "start",
                  lg: "start",
                  md: "start",
                  sm: "start",
                  xs: "center",
                }}
                sx={{
                  color: "#000",
                  fontSize: "1rem",
                  fontFamily: "'Inter', sans-serif",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={()=>{
                  handleClick()
                  HOME()
                }}
              >
                HOME
              </Typography>
              <Typography
                lineHeight={"1.6rem"}
                textAlign={{
                  xl: "start",
                  lg: "start",
                  md: "start",
                  sm: "start",
                  xs: "center",
                }}
                sx={{
                  color: "#000",
                  fontSize: "1rem",
                  fontFamily: "'Inter', sans-serif",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={()=>{
                  handleClick()
                  AboutUs()
                }}
              >
                ABOUTUS
              </Typography>
              <Typography
                lineHeight={"1.6rem"}
                textAlign={{
                  xl: "start",
                  lg: "start",
                  md: "start",
                  sm: "start",
                  xs: "center",
                }}
                sx={{
                  color: "#000",
                  fontSize: "1rem",
                  fontFamily: "'Inter', sans-serif",
                  paddingBottom: "2rem",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={()=>{
                  handleClick()
                  Feedback()
                }}
              >
                 FEEDBACK
              </Typography>
            </Grid>
            <Grid
              container
              xl={2.5}
              lg={2.5}
              md={6}
              sm={6}
              xs={12}
              sx={{
                justifyContent: "space-between",
                flexDirection: "column",
                height: "10rem",
              }}
            >
              <Typography
                lineHeight={"1.6rem"}
                textAlign={{
                  xl: "start",
                  lg: "start",
                  md: "start",
                  sm: "start",
                  xs: "center",
                }}
                sx={{
                  color: "#000",
                  fontSize: "1rem",
                  fontFamily: "'Inter', sans-serif",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={()=>{
                  handleClick()
                }}
              >
                HOTEL
              </Typography>
              <Typography
                lineHeight={"1.6rem"}
                textAlign={{
                  xl: "start",
                  lg: "start",
                  md: "start",
                  sm: "start",
                  xs: "center",
                }}
                sx={{
                  color: "#000",
                  fontSize: "1rem",
                  fontFamily: "'Inter', sans-serif",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={()=>{
                  handleClick()}}
              >
                DAY OUTING
              </Typography>
              <Typography
                lineHeight={"1.6rem"}
                textAlign={{
                  xl: "start",
                  lg: "start",
                  md: "start",
                  sm: "start",
                  xs: "center",
                }}
                sx={{
                  color: "#000",
                  fontSize: "1rem",
                  fontFamily: "'Inter', sans-serif",
                  paddingBottom: "2rem",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                PLACES
              </Typography>
            </Grid>
            <Grid
              container
              item
              xl={2.5}
              lg={2.5}
              md={6}
              sm={6}
              xs={12}
              textAlign={{
                xl: "start",
                lg: "start",
                md: "start",
                sm: "start",
                xs: "center",
              }}
            >
              <Grid item xs={12} sx={{display:"flex", justifyContent:{xs: "center", sm: "start",}}}>
              <Typography>
                <a
                  href="https://www.w3schools.com/"
                  style={{ textDecoration: "none", marginRight: "1rem" }}
                >
                  IG
                </a>
              </Typography>
              <Typography>
                <a
                  href="https://www.facebook.com/CeylonMr.traveler?mibextid=ZbWKwL"
                  style={{ textDecoration: "none" }}
                >
                  Fb
                </a>
              </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  textAlign={{
                    xl: "start",
                    lg: "start",
                    md: "start",
                    sm: "start",
                    xs: "center",
                  }}
                >
                  © 2023 Mr traveler
                </Typography>
                <Typography
                  lineHeight={"1.6rem"}
                  paddingTop={"1rem"}
                  textAlign={{
                    xl: "start",
                    lg: "start",
                    md: "start",
                    sm: "start",
                    xs: "center",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={()=>{
                  Terms()
                  handleClick()
                }}
                >
                  Terms
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Footer;
