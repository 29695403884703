import React, { useState } from "react";
// import '../Navigation/navBar.css';
// import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
// import SearchIcon from "@mui/icons-material/Search";
// import AddCommentIcon from "@mui/icons-material/AddComment";
// import { useNavigate } from "react-router-dom";
// import RequestQuote from '../../../screens/requestQuote/RequestQuote';
// import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
// import Badge from "@mui/material/Badge";
// import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
// import { useLocation } from "react-router-dom";
import logo from "../images/logo.png";
// import Tooltip from "@mui/material/Tooltip";
// import ProfilePopup from './profilePopup';
// import PermIdentityIcon from "@mui/icons-material/PermIdentity";
// import { useDispatch } from "react-redux";
// import { logout } from "../../../store/actions/authActions";
import { Typography, Grid } from "@mui/material";
// import FieldButtons from "../common/fieldButton/FieldButtons";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import SubjectIcon from "@mui/icons-material/Subject";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
// import Booking from "../booking/Booking";

import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function NavBar() {
  const location = useLocation();
  const path = location.pathname;

  // const show = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const HOME = () => {
    navigate("/");
    setMobileOpen(false);
  };
  const AboutUs = () => {
    navigate("/about-us");
    setMobileOpen(false);
  };
  const Feedback = () => {
    navigate("/feedback");
    setMobileOpen(false);
  };

  let navigate = useNavigate();
  // const dropDownhandle = () => {
  //   setShow(!show);
  // };
  //   const handleBooking = () => {
  //   <Booking />
  // }
  const drawer = (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      <Grid
        container
        sx={{ p: "1rem" }}
        justifyContent={"space-between"}
        alignContent={"center"}
      >
        <Grid item>
          <Typography
            sx={{
              color: "#303030",
              fontFamily: "'Inter', sans-serif",
              fontSize: "1.2rem",
              fontWeight: "700",
            }}
            onClick={handleDrawerToggle}
          >
            <img
              src={logo}
              alt="hotels"
              style={{ height: "1.5rem", paddingTop: "10px" }}
            />
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={handleDrawerToggle}>
            {mobileOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
      <List sx={{ display: "flex", flexDirection: "column", color: "#000000" }}>
        <ListItem disablePadding >
          <ListItemButton
            className={path === "/"}
            onClick={() => {
              HOME();
            }}
            sx={{
              justifyContent: "center", alignContent: "center", textAlign: "center",
              fontWeight: path === "/" ? 700 : 400,
            }}
          >
            Home{" "}
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            className={path === "/about-us"}
            onClick={() => {
              AboutUs();
            }}
            sx={{
              justifyContent: "center", alignContent: "center", textAlign: "center",
              fontWeight: path === "/about-us" ? 700 : 400,
            }}
          >
            AboutUs
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            className={path === "/feedback"}
            onClick={() => {
              Feedback();
            }}
            sx={{
              justifyContent: "center", alignContent: "center", textAlign: "center",
              fontWeight: path === "/feedback" ? 700 : 400,
            }}
          >
            FeedBack
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
  return (
    <Grid
      container
      item
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      sx={{
        justifyContent: "center",
        alignContent: "center",
        backgroundColor: "#ffffff",
      }}
    >
      <AppBar
        component="nav"
        sx={{
          background: "white",
          justifyContent: "center",
          alignItems: "center",
          padding: {
            sm: "0",
            xs: "5px 0",
          },
        }}
      >
        <Grid
          container
          item
          sm={11.5}
          xs={11}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid
            container
            xl={4}
            lg={4}
            md={5}
            sm={5}
            xs={5}
            display={{ xs: "none", sm: "block" }}
            sx={{
              justifyContent: "flex-start",
              alignItems: "center",
              order: {
                md: 1,
                xs: 2,
              },
            }}
          >
            <Grid item>
              <List sx={{ display: "flex", flexDirection: "row", color: "#000000" }}>
                <ListItem disablePadding>
                  <ListItemButton
                    className={path === "/"}
                    onClick={() => {
                      HOME();
                    }}
                    sx={{
                      textAlign: "center",
                      fontWeight: path === "/" ? 700 : 400,
                    }}
                  >
                    Home{" "}
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    className={path === "/about-us"}
                    onClick={() => {
                      AboutUs();
                    }}
                    sx={{
                      textAlign: "center",
                      fontWeight: path === "/about-us" ? 700 : 400,
                    }}
                  >
                    AboutUs
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                  <ListItemButton
                    className={path === "/feedback"}
                    onClick={() => {
                      Feedback();
                    }}
                    sx={{
                      textAlign: "center",
                      fontWeight: path === "/feedback" ? 700 : 400,
                    }}
                  >
                    FeedBack
                  </ListItemButton>
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Grid
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            sx={{
              justifyContent: "center",
              alignContent: "center",
              order: {
                md: 2,
                xs: 1,
              },
            }}
          >
            <Grid
              container
              item
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Grid
                item
                sx={{ justifyContent: "center", alignContent: "center" }}
              >
                <Grid item onClick={() => {
                  HOME();
                }}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  <img
                    src={logo}
                    alt="hotels"
                    style={{ height: "2rem", paddingTop: "5px" }}
                  />
                </Grid>{" "}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xl={4}
            lg={4}
            md={5}
            sm={5}
            xs={5}
            sx={{
              flexDirection: "row",
              justifyContent: "end",
              alignContent: "center",
              order: 3,
            }}
          >
            <Grid item>
              <IconButton
                onClick={handleDrawerToggle}
                sx={{
                  backgroundColor: "#000000",
                  color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#34E0A1",
                    // Adjust the text color as needed for the hover state
                    color: "#ffffff",
                  },
                }}
              >
                <SubjectIcon />
              </IconButton>
            </Grid>
            <nav>
              <Drawer
                variant="temporary"
                open={mobileOpen}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: {
                      xl: "20%",
                      lg: "25%",
                      md: "40%",
                      sm: "40%",
                      xs: "90%",
                    },
                    backgroundColor: "#ffffff",
                  },
                }}
              >
                {drawer}
              </Drawer>
            </nav>
          </Grid>
        </Grid>
      </AppBar>
    </Grid>
  );
}

export default NavBar;
