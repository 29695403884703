import React from 'react';
import "./styles.css";

export default function Loader() {
  return (
    <div className={"maindiv"}>
      <span className={"loader"}></span>
    </div>
  );
}
