import * as React from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import logo from "../images/logo.png";
import Divider from "@mui/material/Divider";
import moment from "moment";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

export default function BookingPopup({
  secondopen,
  setSecondopen,
  secondhandleClose,
  sltitem,
  userName,
  userEmail,
  contactNumber,
  type,
  adults,
  checkIn,
  checkOut,
}) {
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
  const handleClose = () => {
    setSecondopen(false);
    secondhandleClose(false);
  };
  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        onClose={handleClose}
        open={secondopen}
      >
        <Grid
          container
          xs={12}
          sx={{
            justifyContent: "center",
            alignContent: "center",
            zIndex: 20,
          }}
        >
          <Grid xs={12} sx={{ justifyContent: "flex-end" }}>
            <IconButton
              aria-label="close"
              onClick={() => {
                window.location.reload();
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#303030",
              }}
            >
              <CloseIcon />{" "}
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: "500",
                }}
              >
                Close
              </Typography>
            </IconButton>
          </Grid>
          <DialogContent>
            <Grid container item xs={11}>
              <Grid
                container
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Grid
                  container
                  item
                  sx={{ justifyContent: "center", alignContent: "center" }}
                >
                  <img src={logo} alt="hotels" style={{ height: "3rem" }} />
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      color: "#000",
                      fontFamily: "'Inter', sans-serif",
                      fontSize: "1.2rem",
                      fontWeight: "600",
                      textAlign: "center",
                      padding: "1.3rem 0",
                    }}
                  >
                    Congratulations! Your Journey Begins Here
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      color: "#757575",
                      fontFamily: "'Inter', sans-serif",
                      fontSize: "0.85rem",
                      fontWeight: "400",
                      paddingBottom: "1rem",
                      textAlign: "center",
                      paddingTop: "1.3rem",
                    }}
                  >
                    Your hotel booking is confirmed and your adventure awaits.
                    Get ready to unwind in style, make memories, and experience
                    extraordinary hospitality. We can't wait to host you!
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item md={6} sm={12} paddingTop={"1.5rem"}>
                <Typography
                  style={{
                    color: "#303030",
                    fontFamily: "'Inter', sans-serif",
                    fontSize: "1rem",
                    fontWeight: "500",
                    paddingBottom: "1.5rem",
                    textAlign: "start",
                  }}
                >
                  Your experience
                </Typography>
                <Grid
                  item
                  container
                  paddingBottom={"1rem"}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignContent: "flex-start",
                  }}
                >
                  <Typography
                    style={{
                      color: "#303030",
                      fontFamily: "'Inter', sans-serif",
                      fontSize: "1rem",
                      fontWeight: "500",
                      textAlign: "start",
                      paddingBottom: "1rem",
                    }}
                  >
                    Date
                  </Typography>
                  <Typography
                    style={{
                      color: "#303030",
                      fontFamily: "'Inter', sans-serif",
                      fontSize: "0.85rem",
                      fontWeight: "400",
                      textAlign: "start",
                    }}
                  >
                    {moment(checkIn).format('MM/DD/YYYY')}-{" "}
                    {moment(checkOut).format('MM/DD/YYYY')}
                    <span>(1 night stay)</span>
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  paddingBottom={"1rem"}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignContent: "flex-start",
                  }}
                >
                  <Typography
                    style={{
                      color: "#303030",
                      fontFamily: "'Inter', sans-serif",
                      fontSize: "1rem",
                      fontWeight: "500",
                      textAlign: "start",
                      paddingBottom: "1rem",
                    }}
                  >
                    Guest
                  </Typography>
                  <Typography
                    style={{
                      color: "#303030",
                      fontFamily: "'Inter', sans-serif",
                      fontSize: "0.85rem",
                      fontWeight: "400",
                      textAlign: "start",
                    }}
                  >
                    ({adults} guests)
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  paddingBottom={"1rem"}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignContent: "flex-start",
                  }}
                >
                  <Typography
                    style={{
                      color: "#303030",
                      fontFamily: "'Inter', sans-serif",
                      fontSize: "1rem",
                      fontWeight: "500",
                      textAlign: "start",
                      paddingBottom: "1rem",
                    }}
                  >
                    Your details
                  </Typography>
                  <Typography
                    style={{
                      color: "#303030",
                      fontFamily: "'Inter', sans-serif",
                      fontSize: "0.85rem",
                      fontWeight: "400",
                      textAlign: "start",
                    }}
                  >
                    Customer Name: {userName}
                  </Typography>
                  <Typography
                    style={{
                      color: "#303030",
                      fontFamily: "'Inter', sans-serif",
                      fontSize: "0.85rem",
                      fontWeight: "400",
                      textAlign: "start",
                    }}
                  >
                    Email: {userEmail}
                  </Typography>
                  <Typography
                    style={{
                      color: "#303030",
                      fontFamily: "'Inter', sans-serif",
                      fontSize: "0.85rem",
                      fontWeight: "400",
                      textAlign: "start",
                    }}
                  >
                    Telephone: {contactNumber}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                item
                md={6}
                sm={12}
                sx={{ border: "solid 1px #BDBDBD", padding: "1rem" }}
              >
                <Grid item container xs={12}>
                  <Grid
                    item
                    container
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid item md={6} sm={12}>
                      <img
                        src={sltitem?.image1}
                        alt="cardImage"
                        style={{
                          objectFit: "cover",
                          height: "6rem",
                          width: "6rem",
                        }}
                      />
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <Typography
                        style={{
                          color: "#303030",
                          fontFamily: "'Inter', sans-serif",
                          fontSize: "0.9rem",
                          fontWeight: "700",
                          textAlign: "start",
                        }}
                      >
                        {sltitem?.name}
                      </Typography>
                      <Typography
                        style={{
                          color: "#303030",
                          fontFamily: "'Inter', sans-serif",
                          fontSize: "0.8rem",
                          fontWeight: "500",
                          textAlign: "start",
                          paddingBottom: "1rem",
                          textTransform: "capitalize",
                        }}
                      >
                        Type: {sltitem?.packageType}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          color: "#303030",
                          fontFamily: "'Inter', sans-serif",
                          fontSize: "1rem",
                          fontWeight: "500",
                          textAlign: "start",
                        }}
                      >
                        Price details
                      </Typography>
                      <Typography
                        style={{
                          color: "#303030",
                          fontFamily: "'Inter', sans-serif",
                          fontSize: "1rem",
                          fontWeight: "500",
                          textAlign: "start",
                          paddingBottom: "1rem",
                        }}
                      >
                        LKR {sltitem?.minimumCost}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        style={{
                          color: "#303030",
                          fontFamily: "'Inter', sans-serif",
                          fontSize: "1rem",
                          fontWeight: "500",
                          textAlign: "end",
                          paddingBottom: "1rem",
                        }}
                      >
                        LKR {sltitem?.minimumCost}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider
                    sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
                  />
                  <Grid
                    item
                    container
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid item xs={6}>
                      <Typography
                        style={{
                          color: "#303030",
                          fontFamily: "'Inter', sans-serif",
                          fontSize: "1rem",
                          fontWeight: "700",
                          textAlign: "start",
                          paddingBottom: "1rem",
                        }}
                      >
                        Total(LKR)
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        style={{
                          color: "#303030",
                          fontFamily: "'Inter', sans-serif",
                          fontSize: "1rem",
                          fontWeight: "700",
                          textAlign: "end",
                          paddingBottom: "1rem",
                        }}
                      >
                        LKR {sltitem?.minimumCost}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Grid>
      </BootstrapDialog>
    </div>
  );
}


// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiDialogContent-root': {
//     padding: theme.spacing(2),
//   },
//   '& .MuiDialogActions-root': {
//     padding: theme.spacing(1),
//   },
// }));

// export default function CustomizedDialogs() {
//   const [open, setOpen] = React.useState(false);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <div>
//       <Button variant="outlined" onClick={handleClickOpen}>
//         Open dialog
//       </Button>
//       <BootstrapDialog
//         onClose={handleClose}
//         aria-labelledby="customized-dialog-title"
//         open={open}
//       >
//         <IconButton
//           aria-label="close"
//           onClick={handleClose}
//           sx={{
//             position: 'absolute',
//             right: 8,
//             top: 8,
//             color: (theme) => theme.palette.grey[500],
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//         <DialogContent>
          
//         </DialogContent>
//       </BootstrapDialog>
//     </div>
//   );
// }