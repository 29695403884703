import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import { Grid, Box, Typography } from "@mui/material";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import TuneIcon from "@mui/icons-material/Tune";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const drawerBleeding = 5;

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
  borderRadius: "0.7rem 0.7rem 0rem 0rem",
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[500] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 10,
  left: 'calc(50% - 15px)',
}));

function SwipeableEdgeDrawer(props) {
  const { window, setFilter, filter } = props;
  const [open, setOpen] = React.useState(false);
  const [type, setType] = useState("hotel");
  const [price, setPrice] = useState("");
  const [location, setLocation] = useState("");

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // This is used only for the example
  const container = window !== undefined ? () => window().document.body : undefined;


  const hotel = [
    "hotel",
    "place",
    "dayout",
    "honeymoon",
  ];

  const district = [
    "Colombo",
    "Gampaha",
    "Kalutara",
    "Kandy",
    "Matale",
    "Nuwara-Eliya",
    "Galle",
    "Matara",
    "Hambantota",
    "Jaffna",
    "Kilinochchi",
    "Mannar",
    "Vavuniya",
    "Mullaitivu",
    "Batticaloa",
    "Ampara",
    "Trincomalee",
    "Kurunegala",
    "Puttalam",
    "Anuradhapura",
    "Polonnaruwa",
    "Badulla",
    "Moneragala",
    "Ratnapura",
    "Kegalle",
  ];

  const prices = [
      "100000",
      "25000",
      "12000",
      "9000",
  ];

  const districtProps = {
    options: district,
    getOptionLabel: (option) => option,
  };
  const hotelProps = {
    options: hotel,
    getOptionLabel: (option) => option,
  };
  const priceProps = {
    options: prices,
    getOptionLabel: (option) => option,
  };

  // const enabledButtonStyle = {
  //   width: "100%",
  //   borderRadius: "none",
  //   textTransform: "capitalize",
  //   color: "#ffffff",
  //   backgroundColor: "#34E0A1",
  //   fontFamily: profile?.style?.fontFamily,
  // };

  // const disabledButtonStyle = {
  //   ...enabledButtonStyle,
  //   backgroundColor: "#CCCCCC", // Grayed out background color
  //   pointerEvents: "none", // Disable pointer events on the button
  //   cursor: "not-allowed", // Change the cursor to 'not-allowed'
  // };
  return (
    <Root>
      <CssBaseline />
      <Global
        sx={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(55% - ${drawerBleeding}px)`,
            overflow: 'visible',
          },
        }}
      />

        <Button
          variant="outlined"
          sx={{
            textTransform: "capitalize",
            color: "#000",
            "&.MuiButtonBase-root": {
              border: "none",
            },
            width: "8rem",
          }}
          onClick={toggleDrawer(true)}
        >
          <TuneIcon sx={{ paddingRight: "0.7rem" }} />
          All Filters
        </Button>

      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: {
            sm: "none"
          },
        }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
          }}
          onClick={toggleDrawer(false)}
        >
          <Puller />

        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: '100%',
            overflow: 'auto',
          }}
        >
          <Grid container sx={{ justifyContent: "space-between", alignContent: "center", margin:'0.8rem 0' }}>
            <Typography
              sx={{
                color: "#000",
                fontSize: "18px",
                fontWeight: 600,
                fontFamily: "'Inter', sans-serif",
                margin: "1.5rem 0"
              }}
            >
              FILTER
            </Typography>
            <Button
            sx={{
              color: "#D8D8D8",
                fontSize: "15px",
                fontWeight: 600,
                fontFamily: "'Inter', sans-serif",
                margin: "1.5rem 0",
                padding:0,
                background:"none"
            }}
            onClick={() => {
              setFilter({ type: "hotel", price: 50000, location: "" });
              toggleDrawer(false);
            }}
          >RESET
          </Button>
          </Grid>

          <Grid
            item
            sx={{
              textTransform: "capitalize",
              borderRadius: 1,
              color: "#000",
              marginRight: "1.5rem",
              border: "1px solid #BDBDBD",
              padding: "4px 3px",
              width: "100%",

            }}
          >
            <Autocomplete
              {...hotelProps}
              id="type"
              value={type}
              openOnFocus
              marginRight={"1.5rem"}
              sx={{
                "&.MuiAutocomplete-root": {
                  width: "100%"
                },
              }
              }
              onChange={(event, newValue) => {
                setType(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Type"
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid
            item
            sx={{
              textTransform: "capitalize",
              borderRadius: 1,
              color: "#000",
              marginRight: "1.5rem",
              border: "1px solid #BDBDBD",
              padding: "4px 3px",
              marginTop: "1rem",
              width: "100%",
            }}
          >
            <Autocomplete
              {...districtProps}
              value={location}
              id="district"
              openOnFocus
              marginRight={"1.5rem"}
              sx={{
                "&.MuiAutocomplete-root": {
                  width: "100%"
                },
              }
              }
              onChange={(event, newValue) => {
                setLocation(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="District"
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid
            item
            sx={{
              textTransform: "capitalize",
              borderRadius: 1,
              color: "#000",
              marginRight: "1.5rem",
              border: "1px solid #BDBDBD",
              padding: "4px 3px",
              marginTop: "1rem",
              width: "100%",
            }}
          >
            <Autocomplete
              {...priceProps}
              id="price"
              value={price}
              openOnFocus
              marginRight={"1.5rem"}
              sx={{
                "&.MuiAutocomplete-root": {
                  width: "100%"
                },
              }
              }
              onChange={(event, newValue) => {
                setPrice(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="price"
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Button
            //  sx={{ dfgdfg === 2000  ? disabledButtonStyle : enabledButtonStyle }}
            disabled={
              !type &&
              !price &&
              !location
            }
            sx={{
              textTransform: "capitalize",
              borderRadius: 1,
              color: "#ffffff",
              backgroundColor: "#34E0A1",
              marginRight: "1.5rem",
              padding: "4px 3px",
              marginTop: "1rem",
              marginBottom: "2rem",
              width: "100%",
            }}
            variant="contained"
            onClick={() => {
              setFilter({ ...filter, type: type, price: price ? price : "100000", location: location });
            }}
          >show results
          </Button>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

SwipeableEdgeDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SwipeableEdgeDrawer;
