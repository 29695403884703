import Home from "./pages/Home";
import "./App.css";
import React, { Suspense, useRef }  from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import NavBar from "./components/Navbar/NavBar";
import Footer from "./components/footer/Footer";
import AboutUs from "./components/aboutUs/AboutUs";
import FeedBack from "./components/feedback/FeedBack";
import Terms from "./components/Terms/TermsConditions";
import ReactGA from "react-ga4";


function App() {
  const scroolRef = useRef(null);

  const handleClick = () => {
    scroolRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const TRACKING_ID = 'G-TQ7FK5VJGR';
  ReactGA.initialize(TRACKING_ID);

  // Send pageview with a custom path
  ReactGA.send({ hitType: "pageview", page: `${document.location.pathname}` });
  return (
    <BrowserRouter>
      <NavBar />
      <Suspense fallback={<div>{/* <Loader /> */} " loading"</div>}>
        <Routes>
          <Route path="/" element={<Home scroolRef={scroolRef} handleClick={handleClick}/>} />
          <Route path="/feedback" element={<FeedBack />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </Suspense>
      <Footer handleClick={handleClick}/>
    </BrowserRouter>
  );
}

export default App;
