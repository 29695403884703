import React, { useState } from "react";
import {
  Grid,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Alert,
  Link 
} from "@mui/material";
// @ts-ignore
import { TextField } from "formik-mui";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import images from "../images/Feedback (2).webp";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import axios from "../../lib/axios";

const items = [
  {
    name: "Bug",
    value: "bug",
  },
  {
    name: "Feature Request",
    value: "feature-request",
  },
  {
    name: "Positive",
    value: "positive",
  },
  {
    name: "Negative",
    value: "negative",
  },
  {
    name: "User Experience",
    value: "user-experience",
  },
];

export default function Feedback() {


  const marginsSm = useMediaQuery("(max-width: 899px)");
  const marginsSmField = useMediaQuery("(max-width: 1920px)");
  const initialValues = {
    feedbackType: "bug",
    description: "",
    name: "",
    email: "",
  };

  const [alert, setAlert] = useState({
    show: false,
    message: "",
    severity: "success",
  });

  const validationSchema = Yup.object().shape({
    feedbackType: Yup.string().required(),
    description: Yup.string()
      .required("Feedback description is required.")
      .min(2,"Min length for the feedback description is 2.")
      .max(500, "Max length for the feedback description is 500."),
    name: Yup.string().min(3,"Min length for the name is 3.")
      .max(30, "Max length for the name is 30."),
    email: Yup.string().email(),
  });

  const submit = async (e, { resetForm }) => {
    try {
      await axios.post("/feedback/", {
        feedbackType: e.feedbackType,
        description: e.description,
        name: e.name,
        email: e.email,
      });
      resetForm();
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Feedback send successfully",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Feedback send successfully",
          }),
        5000
      );
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Server error!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Server error!",
          }),
        5000
      );
    }
  };

    return (
      <>
      <Grid
        container
        sx={{ justifyContent: "center" }}
        xs={12}
      >
        <img
          src={images}
          alt="cardImage"
          style={{
            objectFit: "cover",
            height: "40vh",
            width: "100%",
            filter: "grayscale(30%)"
          }}
        />
        <Typography sx={{
          position: "absolute",
          color: "#fff",
          textAlign: "center",
          fontSize: {lg:"50px", sm: "45px", xs: "35px"},
          fontFamily: "'Inter', sans-serif",
          top: {lg:"15%", sm:"13%", xs:"10%"}, 
          // width:{lg:"30%", sm:"45%", xs:"70%" },
        }}>
           Your Feedback
        </Typography>
        <Typography sx={{
          position: "absolute", 
          textAlign: "center", 
          color: "#fff", 
          top: {lg:"25%", sm:"23%", xs:"20%"}, 
          width:{lg:"40%", sm:"55%", xs:"80%" },
          fontSize: {lg:"17px", sm: "17px", xs: "13px"}, 
          fontFamily: "'Inter', sans-serif",
          lineHeight:"24px"
        }}>
          Your tales of adventure are the beacon guiding fellow travelers to 
          enhance their journeys share your experiences and be the compass that lights their way!
        </Typography>
        </Grid>
        <Grid >
          <Typography
          sx={{
            color: "#303030",
            fontFamily: "'Inter', sans-serif",
            fontSize: "1rem",
            fontweight: "500",
            marginTop: "30px",
            display:"flex", 
            alignItems:"center",
            ml:{ lg:20, md: 20, sm:3.2, xs:1.5}
          }}
          >
          
            <Link href="/"  color="#2A2927" underline="none" >
                Home
            </Link>
            <NavigateNextIcon />
            <span style={{fontWeight:"bold"}}> Feedback </span>
          </Typography>
        </Grid>
        <Grid
          sx={{
            paddingTop: {
              lg: "1rem",
              md: "1rem",
              sm: "0.5rem",
              xs: "0.5rem",
            },
            paddingBottom: {
              lg: "2rem",
              md: "2rem",
              sm: "2.875rem",
              xs: "2.875rem",
            },
            paddingLeft: {
              lg: "10rem",
              md: "10rem",
              sm: "1.625rem",
              xs: "0.75rem",
            },
            paddingRight: {
              lg: "10rem",
              md: "10rem",
              sm: "1.563rem",
              xs: "0.813rem",
            },
            backgroundColor: "white",
            justifyContent:"center"
          }}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={validationSchema}
          >
            {({
              errors,
              values,
              isValid,
              dirty,
              handleChange,
              isSubmitting,
            }) => {
              return (
                <Form>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                        sx={{
                          color: "#000000",
                          textAlign: "flex-start",
                          fontWeight: "bold",
                          letterSpacing: 0,
                          opacity: 1,
                          marginLeft: {lg:"0.2rem", xs:0},
                          fontFamily:"Open Sans",
                          paddingBottom: "1rem",
                          fontSize: {
                            xl: "1.2rem",
                            lg: "1.2rem",
                            md: "1.2rem",
                            sm: "1rem",
                            xs: "1rem",
                          },
                          mt:3
                        }}
                      >
                          FEEDBACK TYPE
                      </Typography>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="position"
                          name="feedbackType"
                          defaultValue="bug"
                          sx={{
                            marginBottom: {
                              lg: "1.5rem",
                              md: "1.5rem",
                              sm: "1.5rem",
                              xs: "1.5rem",
                            },
                            marginLeft: {
                              lg: "0.2rem",
                              md: "0.2rem",
                              sm: "0rem",
                              xs: "0px",
                            },
                            fontSize: {
                              xl: "1rem",
                              lg: "0.9rem",
                              md: "0.9rem",
                              sm: "1rem",
                              xs: "0.8rem",
                            },
                          }}
                          onChange={handleChange}
                        >
                          {items.map((item, key) =>
                            marginsSm ? (
                              <Grid item sm={4} xs={6} key={key}>
                                <FormControlLabel
                                  sx={{
                                    color: "#232323",
                                    ".MuiFormControlLabel-label": {
                                      fontSize: {
                                        xl: "1rem",
                                        lg: "0.9rem",
                                        md: "0.9rem",
                                        sm: "1rem",
                                        xs: "0.8rem",
                                      },
                                      color: "#232323",
                                    },
                                    fontFamily: "SF Pro Display",
                                    marginLeft: {
                                      lg: "0.2rem",
                                      md: "0.2rem",
                                      sm: "1px",
                                      xs: "0px",
                                    },
                                  }}
                                  value={item.value}
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#00000099",
                                        "&.Mui-checked": { color: "#34E0A1" },
                                      }}
                                      color="primary"
                                    />
                                  }
                                  label={item.name}
                                  key={key}
                                />
                              </Grid>
                            ) : (
                              <FormControlLabel
                                sx={{
                                  color: "#232323",
                                  ".MuiFormControlLabel-label": {
                                    fontSize: {
                                      xl: "1rem",
                                      lg: "0.9rem",
                                      md: "0.9rem",
                                      sm: "1rem",
                                      xs: "0.585rem",
                                    },
                                    color: "#232323",
                                  },
                                  fontFamily: "SF Pro Display",
                                  marginLeft:
                                    key !== 0
                                      ? {
                                          lg: "0.2rem",
                                          md: "0.2rem",
                                          sm: "0.2rem",
                                          xs: "0.2rem",
                                        }
                                      : "0",
                                }}
                                value={item.value}
                                control={
                                  <Radio
                                    sx={{
                                      color: "#00000099",
                                      "&.Mui-checked": { color: "#34E0A1" },
                                    }}
                                    color="primary"
                                  />
                                }
                                label={item.name}
                                key={key}
                              />
                            )
                          )}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid
                      container
                      item
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item md={12} sm={12} xs={12}>
                        <div style={{ position: "relative" }}>
                          <Field
                            multiline
                            sx={{
                              marginBottom: {
                                lg: "1rem",
                                md: "1rem",
                                sm: "1rem",
                                xs: "1rem",
                              },
                              fontFamily:"Open Sans",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#D1D1D1",
                                  borderWidth: "2px",
                                  borderRadius: "7px",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#34E0A1",
                                  borderRadius: "7px",
                                  borderWidth: "1px",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#34E0A1",
                                  borderWidth: "2px",
                                  borderRadius: "7px",
                                },
                              },
                            }}
                            maxRows={12}
                            variant="outlined"
                            minRows={9.5}
                            placeholder={`Share your delightful experiences with us`}
                            fullWidth
                            name="description"
                            component={TextField}
                            helperText={errors.description}
                          />
                          <Typography
                            sx={{
                              color: "#707070",
                              fontSize: "1rem",
                              backgroundColor: "#ffff",
                              paddingLeft: "0.5rem",
                              BorderRadiusTopleft: "1rem",
                              bottom: {
                                lg: errors.description ? "65px" : "50px",
                                md: errors.description ? "65px" : "50px",
                                sm: errors.description ? "55px" : "34px",
                                xs: errors.description ? "55px" : "34px",
                              },
                              right: "0.8rem",
                              position: "absolute",
                            }}
                          >
                            {values.description.length}/500
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      alignItems="center"
                      justifyContent="center"
                      mt={2}
                      columnGap={marginsSm ? 0 : 3}
                      rowGap={marginsSm ? 3 : 0}
                    >
                      <Grid item lg={5.9} md={5.8} sm={12} xs={12}>
                        <Field
                          label="Name (Optional)"
                          variant="outlined"
                          fullWidth
                          component={TextField}
                          size={marginsSmField ? "small" : "medium"}
                          sx={{
                            fontFamily:"Open Sans",
                            "& label.Mui-focused": {
                              color: "#34E0A1",
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#4B4A3D",
                                borderWidth: "1px",
                                borderRadius: "7px",
                              },
                              "&:hover fieldset": {
                                borderColor: "#34E0A1",
                                borderRadius: "7px",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#34E0A1",
                                borderRadius: "7px",
                              },
                            },
                          }}
                          name="name"
                        />
                      </Grid>
                      <Grid item lg={5.7} md={5.6} sm={12} xs={12}>
                        <Field
                          label="Email (Optional)"
                          variant="outlined"
                          size={marginsSmField ? "small" : "medium"}
                          sx={{
                            fontFamily:"Open Sans",
                            "& label.Mui-focused": {
                              color: "#34E0A1",
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#4B4A3D",
                                borderWidth: "1px",
                                borderRadius: "7px",
                              },
                              "&:hover fieldset": {
                                borderColor: "#34E0A1",
                                borderRadius: "7px",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#34E0A1",
                                borderRadius: "7px",
                              },
                            },
                          }}
                          fullWidth
                          component={TextField}
                          name="email"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      item
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={4}
                        xs={6}
                      >
                        <Button
                          sx={{
                            backgroundColor: "#36B37E",
                            ":hover": {
                              backgroundColor: "#34E0A1",
                            },
                            fontFamily:"Open Sans",
                            marginTop: "2rem",
                            marginLeft: {
                              lg: "0.2rem",
                              md: "0.2rem",
                              sm: "0rem",
                              xs: "0rem",
                            },
                            fontSize: {
                              xl: "1.1rem",
                              lg: "0.84rem",
                              md: "0.9rem",
                              sm: "1rem",
                              xs: "0.75rem",
                            },
                            fontWeight: "500",
                            borderRadius: "5px",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                          variant="contained"
                          type="submit"
                          disabled={!dirty || !isValid}
                        >
                          SEND FEEDBACK
                        </Button>
                      </Grid>
                    </Grid>
                    {alert.showAlert && (
                        <Grid item md={12} style={{ marginTop: "0.7rem" }}>
                          <Alert
                            severity={alert.severity}
                            onClose={() =>
                              setAlert({
                                ...alert,
                                showAlert: false,
                              })
                            }
                          >
                            {alert.message}
                          </Alert>
                        </Grid>
                      )}
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </>
    );
}
