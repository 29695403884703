import React from "react";
import { Grid, Typography, Link } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import Img from "../images/About Us  3.webp";
import useMediaQuery from "@mui/material/useMediaQuery";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Image1 from "../images/AboutUs Image/Camera Equipments.webp";
import Image2 from "../images/AboutUs Image/It consultant and services.webp";
import Image3 from "../images/AboutUs Image/Tour Plans.webp";
import Image4 from "../images/AboutUs Image/Travel Agency.webp";
import Image5 from "../images/AboutUs Image/campaign equipments.webp";
import Image6 from "../images/AboutUs Image//website creating.webp";

export default function OurStory({ profile }) {
  const itemData = [
    {
      img: Image2,
      title: "Travel agency",
    },
    {
      img: Image6,
      title: "website creating",
    },
    {
      img: Image3,
      title: "Tour Plans",
    },
    {
      img: Image4,
      title: "campaign equipment",
    },
    {
      img: Image5,
      title: " It consultant",
    },
    {
      img: Image1,
      title: "Camera Equipments",
    },
  ];

  const tab = useMediaQuery("(max-width:900px)");
  const mobile = useMediaQuery("(max-width:600px)");

  return (
    <>
      <Grid container sx={{ justifyContent: "center" }} xs={12}>
        <img
          src={Img}
          alt="cardImage"
          style={{
            objectFit: "cover",
            height: "40vh",
            width: "100%",
            filter: "grayscale(30%)",
          }}
        />
        <Typography
          sx={{
            position: "absolute",
            color: "#fff",
            textAlign: "center",
            fontSize: { lg: "50px", sm: "45px", xs: "45px" },
            fontFamily: "'Inter', sans-serif",
            top: { lg: "17%", sm: "17%", xs: "15%" },
            // width:{lg:"30%", sm:"45%", xs:"70%" },
          }}
        >
          Our Story
        </Typography>
        <Typography
          sx={{
            position: "absolute",
            textAlign: "center",
            color: "#fff",
            top: { lg: "27%", sm: "28%", xs: "25%" },
            width: { lg: "40%", sm: "55%", xs: "80%" },
            fontSize: { lg: "17px", sm: "17px", xs: "13px" },
            fontFamily: "'Inter', sans-serif",
            lineHeight: "24px",
          }}
        >
          Explore Our Luxurious Hotel Experience.!
        </Typography>
        <Grid
          item
          container
          xl={9}
          lg={9}
          md={10}
          sm={11}
          xs={11}
          justifyContent={"space-between"}
          alignItems={"start"}
        >
          <Grid>
            <Typography
              sx={{
                color: "#303030",
                fontFamily: "'Inter', sans-serif",
                fontSize: "1rem",
                fontweight: "500",
                marginTop: "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Link href="/" color="#2A2927" underline="none">
                Home
              </Link>
              <NavigateNextIcon />
              <span style={{ fontWeight: "bold" }}> About Us </span>
            </Typography>
          </Grid>
          <Grid
            container
            xs={12}
            sx={{
              justifyContent: "space-between",
              alignContent: "center",
              padding: "3rem 0",
            }}
          >
            <Grid
              item
              xl={5.5}
              lg={5.5}
              md={5.5}
              sm={12}
              xs={12}
              paddingBottom={{ sm: "2rem", xs: "2rem" }}
            >
              <Typography
                style={{
                  color: "#000",
                  textAlign: "start",
                  fontFamily: "'Inter', sans-serif",
                  fontSize: "30px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",
                  marginBottom: "1rem",
                }}
                variant="h2"
              >
                Let's Explore Sri Lanka Tourism
              </Typography>
              <Typography
                style={{
                  color: "#222",
                  fontFamily: "'Inter', sans-serif",
                  fontSize: "1rem",
                  lineHeight: "1.8rem",
                  fontWeight: "400",
                  padding: "1rem 0",
                }}
              >
                Mr Traveller is a leading Tourism guide and travel agent for sri
                lankan Travellers with updated pricing plan of hotel and
                packages,travel places and sri lanka tourist places. Side video
                shows our next implementation for the platform.
              </Typography>
              <Typography
                style={{
                  color: "#222",
                  fontFamily: "'Inter', sans-serif",
                  fontSize: "1rem",
                  lineHeight: "1.8rem",
                  fontWeight: "400",
                  padding: "1rem 0",
                }}
              >
                Our mission is to empower hotels with unique and tailor-made
                websites that not only reflect their individuality but also
                enhance their online success.
              </Typography>
              <Typography
                style={{
                  color: "#222",
                  fontFamily: "'Inter', sans-serif",
                  fontSize: "1rem",
                  lineHeight: "1.8rem",
                  fontWeight: "400",
                  padding: "1rem 0",
                }}
              >
                Contact Us: 076 197 3176 , mrtravelersinfo@gmail.com
              </Typography>
            </Grid>
            <Grid container item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Grid item xs={12}>
                <iframe
                  width="100%"
                  height="280"
                  src="https://www.youtube.com/embed/kz1_YxCNDXo?si=IJaeqwakQqdbInyl"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </Grid>{" "}
            </Grid>
            <Grid
              container
              xs={12}
              sx={{
                justifyContent: "center",
                alignContent: "center",
                padding: "2rem 0",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  justifyContent: "center",
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#000",
                    fontFamily: "'Inter', sans-serif",
                    fontSize: { lg: "40px", sm: "35px", xs: "30px" },
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    marginBottom: "2rem",
                  }}
                >
                  Our Services
                </Typography>
              </Grid>
              <ImageList
                sx={{ width: 1000 }}
                cols={mobile ? 1 : tab ? 2 : 3}
                rowHeight={328}
              >
                {itemData.map((item) => (
                  <ImageListItem key={item.img}>
                    <img
                      srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      src={`${item.img}?w=248&fit=crop&auto=format`}
                      alt={item.title}
                      loading="lazy"
                    />
                    <ImageListItemBar
                      title={item.title}
                      subtitle={item.author}
                      actionIcon={
                        <IconButton
                          sx={{ color: "rgba(255, 255, 255, 0.54" }}
                          aria-label={`info about ${item.title}`}
                        ></IconButton>
                      }
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
