import { Grid, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import ApartmentIcon from "@mui/icons-material/Apartment";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import axios from "../../lib/axios";
import Item from "./Item";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";

import { styled, alpha } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TextField from "@mui/material/TextField";
import Loader from "../loader/Loader";
import FilterPopup from "./FilterPopup";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function Filter({ scroolRef, handleClickPagination }) {
  const [itemList, setItemList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
    type: "hotel",
    price: 0,
    location: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, value) => {
    setPage(value);
    handleClickPagination();
  };

  const district = [
    "Colombo",
    "Gampaha",
    "Kalutara",
    "Kandy",
    "Matale",
    "Nuwara-Eliya",
    "Galle",
    "Matara",
    "Hambantota",
    "Jaffna",
    "Kilinochchi",
    "Mannar",
    "Vavuniya",
    "Mullaitivu",
    "Batticaloa",
    "Ampara",
    "Trincomalee",
    "Kurunegala",
    "Puttalam",
    "Anuradhapura",
    "Polonnaruwa",
    "Badulla",
    "Moneragala",
    "Ratnapura",
    "Kegalle",
  ];

  const defaultProps = {
    options: district,
    getOptionLabel: (option) => option,
  };
  const get = async (page, filter) => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(`/pagination/`, {
        type: filter?.type,
        number: page,
        district: filter?.location,
        under: filter?.price,
      });
      setItemList(data.sub);
      setPageCount(data.count);
      // setPage(data.currentPage);
      setIsLoading(false);
    } catch (error) {
      // setAlert({
      //   showAlert: true,
      //   severity: "error",
      //   message: "Data loading failed!",
      // });
      // setTimeout(
      //   () =>
      //     setAlert({
      //       showAlert: false,
      //       severity: "success",
      //       message: "Data loading failed!",
      //     }),
      //   5000
      // );
    }
  };

  useEffect(() => {
    get(page, filter);
    // eslint-disable-next-line
  }, [page, filter?.type, filter?.location, filter?.price]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Grid
        container
        xs={12}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          paddingTop: {
            sm: "5rem",
            xs: "4rem",
          },
        }}
        ref={scroolRef}
      >
        <Grid
          container
          item
          sx={{ justifyContent: "center", alignItems: "center" }}
          xs={12}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: {
                Xl: "100%",
              },
              overflowX: "scroll",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Grid
              item
              sx={{
                marginLeft: "1.5rem",
                display: { sm: "none", xs: "block" },
              }}
            >
              <FilterPopup filter={filter} setFilter={setFilter} />
            </Grid>

            <Grid item>
              <Button
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  color: filter?.type === "hotel" ? "#34E0A1" : "#000",
                  "&.MuiButtonBase-root": {
                    border:
                      filter?.type === "hotel"
                        ? "1px solid #34E0A1"
                        : "1px solid #BDBDBD",
                    borderRadius: 0,
                  },
                  margin: "0 1.8rem",
                }}
                onClick={() => {
                  setFilter({ ...filter, type: "hotel" });
                  setPage(1);
                }}
              >
                <ApartmentIcon sx={{ paddingRight: "0.7rem" }} />
                Hotels
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                sx={{
                  color: filter?.type === "dayout" ? "#34E0A1" : "#000",
                  textTransform: "capitalize",
                  "&.MuiButtonBase-root": {
                    border:
                      filter?.type === "dayout"
                        ? "1px solid #34E0A1"
                        : "1px solid #BDBDBD",
                    borderRadius: 0,
                  },
                  width: "10rem",
                  marginRight: "1.8rem",
                }}
                onClick={() => {
                  setFilter({ ...filter, type: "dayout" });
                  setPage(1);
                }}
              >
                <RestaurantIcon sx={{ paddingRight: "0.7rem" }} />
                Day outing
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  color: filter?.type === "place" ? "#34E0A1" : "#000",
                  "&.MuiButtonBase-root": {
                    border:
                      filter?.type === "place"
                        ? "1px solid #34E0A1"
                        : "1px solid #BDBDBD",
                    borderRadius: 0,
                    marginRight: "1.8rem",
                  },
                }}
                onClick={() => {
                  setFilter({ ...filter, type: "place" });
                  setPage(1);
                }}
              >
                <BeachAccessIcon sx={{ paddingRight: "0.7rem" }} />
                Places
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  color: filter?.type === "honeymoon" ? "#34E0A1" : "#000",
                  "&.MuiButtonBase-root": {
                    border:
                      filter?.type === "honeymoon"
                        ? "1px solid #34E0A1"
                        : "1px solid #BDBDBD",
                    borderRadius: 0,
                  },
                  marginRight: "1.8rem",
                }}
                onClick={() => {
                  setFilter({ ...filter, type: "honeymoon" });
                  setPage(1);
                }}
              >
                <ApartmentIcon sx={{ paddingRight: "0.7rem" }} />
                Honeymoon
              </Button>
            </Grid>
            <Grid
              item
              sx={{
                textTransform: "capitalize",
                borderRadius: 0,
                color: "#000",
                marginRight: "1.8rem",
                border: "1px solid #BDBDBD",
                padding: "2px 3px",
                width: {
                  md: "12rem",
                  sm: "14rem",
                  xs: "16rem",
                },
              }}
            >
              <Autocomplete
                {...defaultProps}
                id="district"
                value={filter?.location}
                openOnFocus
                marginRight={"1.8rem"}
                sx={{
                  "&.MuiAutocomplete-root": {
                    width: "100%",
                  },
                }}
                onChange={(event, newValue) => {
                  setFilter({ ...filter, location: newValue });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="District"
                    variant="standard"
                  />
                )}
              />
            </Grid>
            {/* <Grid item>
              <Button
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  color: filter?.price === 6000 ? "#34E0A1" : "#000",
                  "&.MuiButtonBase-root": {
                    border:
                      filter?.price === 6000
                        ? "1px solid #34E0A1"
                        : "1px solid #BDBDBD",
                    borderRadius: 0,
                  },
                  marginRight: "1.8rem",
                  width: "12rem",
                }}
                onClick={() => {
                  setFilter({ ...filter, price: 6000 })
                }}
              >
                <MonetizationOnIcon sx={{ paddingRight: "0.7rem" }} />
                Under LKR 6,000
              </Button>
            </Grid> */}
            <Grid item>
              <Button
                variant="outlined"
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                  textTransform: "capitalize",
                  color:
                    filter?.price === 6000 ||
                    filter?.price === 9000 ||
                    filter?.price === 12000 ||
                    filter?.price === 25000
                      ? "#34E0A1"
                      : "#000",
                  "&.MuiButtonBase-root": {
                    border:
                      filter?.price === 6000 ||
                      filter?.price === 9000 ||
                      filter?.price === 12000 ||
                      filter?.price === 25000
                        ? "1px solid #34E0A1"
                        : "1px solid #BDBDBD",
                    borderRadius: 0,
                  },
                  width: "18rem",
                  marginRight: "1.8rem",
                }}
              >
                <MonetizationOnIcon sx={{ paddingRight: "0.7rem" }} />
                {filter.price === 1
                  ? "LKR 500  - LKR 6,000"
                  : filter.price === 2
                  ? "LKR 6000  - LKR 12000"
                  : filter.price === 3
                  ? "LKR 12000  - LKR 25000"
                  : filter.price === 4
                  ? " LKR 25000 - LKR 50000"
                  : filter.price === 5
                  ? "More than LKR 50000"
                  : "Price Range"}
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setFilter({ ...filter, price: 1 });
                  }}
                >
                  LKR 500 - LKR 6,000
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setFilter({ ...filter, price: 2 });
                  }}
                >
                  LKR 6000 - LKR 12000
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setFilter({ ...filter, price: 3 });
                  }}
                >
                  LKR 12000 - LKR 25000
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setFilter({ ...filter, price: 4 });
                  }}
                >
                  LKR 25000 - LKR 50000
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setFilter({ ...filter, price: 5 });
                  }}
                >
                  More than LKR 50000
                </MenuItem>
              </StyledMenu>
            </Grid>
            <Grid item>
              <Button
                disabled={
                  filter?.type === "hotel" &&
                  filter?.price === 100000 &&
                  filter?.location === "" &&
                  page === 1
                }
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  color: "#000",
                  marginRight: "1.5rem",
                  "&.MuiButtonBase-root": {
                    border:
                      filter?.type === "hotel" &&
                      filter?.price === 0 &&
                      filter?.location === "" &&
                      page === 1
                        ? "1px solid #D8D8D8"
                        : "1px solid #000",
                    borderRadius: 0,
                  },
                }}
                onClick={() => {
                  setFilter({
                    type: "hotel",
                    price: 0,
                    location: "",
                  });
                  setPage(1);
                }}
              >
                Reset
              </Button>
            </Grid>
          </Box>
        </Grid>
        <Grid
          container
          item
          xs={10}
          sx={{ justifyContent: "start", alignItems: "start" }}
        >
          {itemList.map((item) => (
            <Grid
              key={item.id}
              item
              xs={12}
              sm={6}
              md={6}
              lg={3}
              sx={{ padding: "1rem" }}
            >
              <Item item={item} type={filter?.type} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem 0",
        }}
      >
        {itemList.length === 0 ? (
          <p>Item Not Found!</p>
        ) : (
          <Stack spacing={2}>
            <Pagination
              count={pageCount}
              defaultPage={page}
              shape="rounded"
              onChange={handleChange}
            />
          </Stack>
        )}
      </Grid>
    </>
  );
}
