import { Grid, Typography, Button, Box } from "@mui/material";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";
import ListItemIcon from "@mui/material/ListItemIcon";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import Booking from "../booking/Booking";
import OtherBooking from "../booking/OtherBooking"
import "./styles.css";
import ApartmentIcon from '@mui/icons-material/Apartment';
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import Wifi from "../images/Facility/Wifi.png";
import Beds from "../images/Facility/Beds.png";
import Pool from "../images/Facility/Pool.png";
import Kitchen from "../images/Facility/Kitchen.png";
import Lunch from "../images/Facility/Lunch.png";
import Drink from "../images/Facility/Drink.png";
import Room from "../images/Facility/Public Changing Room.png";
import Balcony from "../images/Facility/Balcony.png";
import Spa from "../images/Facility/Spa.png";
import Bar from "../images/Facility/Bar.jpg";
import Parking from "../images/Facility/FRee Parking.png";
import Security from "../images/Facility/Security.png";
import Beachfront from "../images/Facility/Beachfront.png";
import Airport from "../images/Facility/Airport shuttle.png";
import Bathroom from "../images/Facility/bathroom.png";
import Sea from "../images/Facility/Sea view.png";
import TV from "../images/Facility/TV.png";
import ACRoom from "../images/Facility/AC Room.jpg";
import Cake from "../images/Facility/Cake.png";
import Photo from "../images/Facility/Photo.png";


export default function Item({ item, type }) {

  const filterImages = [
    {
      img: Wifi,
      id: '654d94a01cb022001ce0827e'
    },
    {
      img: Beds,
      id: '654d95051cb022001ce08283',
    },
    {
      img: Pool,
      id: '654d95391cb022001ce08287',
    },
    {
      img: Kitchen,
      id: '654d956c1cb022001ce0828b',
    },
    {
      img: Lunch,
      id: '654d9750807f7f001d304e5a',
    },
    {
      img: Drink,
      id: '654d9786807f7f001d304e5e',
    },
    {
      img: Room,
      id: '654d9ab7a058ac001c86a9f6',
    },
    {
      img: Balcony,
      id: '654d9c2ca058ac001c86a9fa',
    },
    {
      img: Spa,
      id: '654d9c81a058ac001c86a9fe',
    },
    {
      img: Bar,
      id: '654d9cb5a058ac001c86aa02',
    },
    {
      img: Parking,
      id: '654d9d16a058ac001c86aa07',
    },
    {
      img: Security,
      id: '654d9d7da058ac001c86aa0b',
    },
    {
      img: Beachfront,
      id: '654d9e7da058ac001c86aa10',
    },
    {
      img: Airport,
      id: '654d9ee5a058ac001c86aa14',
    },
    {
      img: Bathroom,
      id: '654d9fdda058ac001c86aa18',
    },
    {
      img: Sea,
      id: '654da012a058ac001c86aa1d',
    },
    {
      img: TV,
      id: '654da063a058ac001c86aa21',
    },
    {
      img: ACRoom,
      id: '654dc9ce51aa07001cb8ccdb',
    },
    {
      img: Cake,
      id: '654e400ba1da90001d0df111',
    },
    {
      img: Photo,
      id: '654e40860ce20f001c3da5c9',
    },

  ];
  const [open, setOpen] = useState(false);
  const [openFacility, setOpenFacility] = useState(false);

  const showMoresHandler = () => {
    setOpenFacility(!openFacility);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const newFacility = item?.hotelFeature?.facility?.slice(0, 4);
  const newItemFacility = item?.facility?.slice(0, 4);

  // const returnRandomImage = (item) => {
  //   const rand = Math.floor(Math.random() * 5) + 1;
  //   if(rand === 2){
  //     return item.image2;
  //   }else if (rand === 3){
  //     return item.image3;
  //   }else{
  //     return item.image1;
  //   }
  // }

  return (
    <Box
      container
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      onMouseLeave={() => { setOpenFacility(false) }}
      sx={{
        transition: "background-color 0.5s", // Add a smooth transition for the hover effect
        "&:hover": {
          boxShadow: 3,
        },
      }}
    >
      <Grid item sx={{ position: "relative"}}xl={12} lg={12} md={12} sm={12} xs={12}>
        {type === "hotel" ? (
          <>
            <Grid sx={{
              position: "absolute", top: "8px", left: "16px", backgroundColor: "#fff", zIndex: 2,
              display: "flex", padding: "5px 10px",
            }}>
              <ApartmentIcon sx={{ padding: "0 5px 0 0", fontSize: "1.3rem" }} />
              <Typography sx={{ fontSize: "0.9rem", fontFamily: "'Inter', sans-serif" }}>
                Hotel
              </Typography>
            </Grid>
            <Grid sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}>
              <img
                src={item?.gallery?.galleryimage1 ? item?.gallery?.galleryimage1 : item?.accommodation?.length ? item?.accommodation[0]?.image1 : ''}
                alt="cardImage"
                onClick={() => {
                  handleClickOpen();
                }}
                style={{
                  objectFit: "cover",
                  height: "15rem",
                  width: "100%",
                }} />
            </Grid>
          </>
        ) : (
          <>
            <Grid sx={{
              position: "absolute", top: "8px", left: "16px", backgroundColor: "#fff", zIndex: 1,
              display: "flex", padding: "5px 10px",
            }}>
              {type === "place" ? <BeachAccessIcon sx={{ padding: "0 5px 0 0", fontSize: "1.3rem" }} />
                : type === "dayout" ? <RestaurantIcon sx={{ padding: "0 5px 0 0", fontSize: "1.3rem" }} />
                  : <ApartmentIcon sx={{ padding: "0 5px 0 0", fontSize: "1.3rem" }} />
              }
              <Typography sx={{ fontSize: "0.9rem", fontFamily: "'Inter', sans-serif" }}>
                {type === "place" ? "Place" : type === "dayout" ? "Day out" : "Honeymoon"}
              </Typography>
            </Grid>
            <Grid sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}>
              <img
                src={item.image1}
                alt="cardImage"
                onClick={() => {
                  handleClickOpen();
                }}
                style={{
                  objectFit: "cover",
                  height: "15rem",
                  width: "100%",
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
      >
        <Grid sx={{ padding: "1rem 0.7rem" }}>
          <Typography
            sx={type === "place" ? {
              color: "#000",
              fontFamily: "'Inter', sans-serif",
              fontSize: "1.2rem",
              fontWeight: "600",
              textAlign: "start",
            } : {
              color: "#000",
              fontFamily: "'Inter', sans-serif",
              fontSize: "1.2rem",
              fontWeight: "600",
              textAlign: "start",
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
            onClick={() => {
              handleClickOpen();
            }}
          >
            {item.name}
          </Typography>
          <a
            href={item.maplink}
            target="_blank"
            rel="noreferrer"
            style={{ color: "#000000", textDecoration: "none" }}
          >
            <Typography
              sx={{
                color: "#303030",
                fontFamily: "'Inter', sans-serif",
                fontSize: "1rem",
                fontWeight: "400",
                paddingBottom: "1rem",
                "&:hover": {
                  cursor: "pointer",
                  textDecoration: "underline",
                },
              }}
            >
              {item.district}

            </Typography>
          </a>
          <Grid
            container
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            paddingBottom={"1rem"}
          >

            {!openFacility
              ? newFacility?.map((item) => (
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  sx={{
                    color: "#222",
                    fontFamily: "'Inter', sans-serif",
                    fontSize: "1rem",
                    fontWeight: "400",
                    paddingLeft: 0,
                    paddingBottom: "8px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "25px",
                    }}>
                    <img
                      src={filterImages.find((i) => i.id === item?.facilityId)?.img}
                      alt="cardImage"
                      style={{
                        height: "15px",
                        width: "15px",
                      }}
                    />
                  </ListItemIcon>
                  {item?.name?.length > 9
                    ? `${item?.name.substring(0, 9)}...`
                    : item?.name}
                </Grid>
              ))
              : item?.hotelFeature?.facility?.map((item) => (
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  sx={{
                    color: "#222",
                    fontFamily: "'Inter', sans-serif",
                    fontSize: "1rem",
                    fontWeight: "400",
                    paddingLeft: 0,
                    paddingBottom: "8px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "25px",
                    }}>
                    <img
                      src={filterImages.find((i) => i.id === item?.facilityId)?.img}
                      alt="cardImage"
                      style={{
                        height: "15px",
                        width: "15px",
                      }}
                    />
                  </ListItemIcon>
                  {item?.name?.length > 9
                    ? `${item?.name.substring(0, 9)}...`
                    : item?.name}
                </Grid>
              ))}
            {!openFacility
              ? newItemFacility?.map((item) => (
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  sx={{
                    color: "#222",
                    fontFamily: "'Inter', sans-serif",
                    fontSize: "1rem",
                    fontWeight: "400",
                    paddingLeft: 0,
                    paddingBottom: "8px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "25px",
                    }}>
                    <img
                      src={filterImages.find((i) => i.id === item?.facilityId)?.img}
                      alt="cardImage"
                      style={{
                        height: "15px",
                        width: "15px",
                      }}
                    />
                  </ListItemIcon>
                  {item?.name?.length > 9
                    ? `${item?.name.substring(0, 9)}...`
                    : item?.name}
                </Grid>
              ))
              : item?.facility?.map((item) => (
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  sx={{
                    color: "#222",
                    fontFamily: "'Inter', sans-serif",
                    fontSize: "1rem",
                    fontWeight: "400",
                    paddingLeft: 0,
                    paddingBottom: "8px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "25px",
                    }}>
                    <img
                      src={filterImages.find((i) => i.id === item?.facilityId)?.img}
                      alt="cardImage"
                      style={{
                        height: "15px",
                        width: "15px",
                      }}
                    />
                  </ListItemIcon>
                  {item?.name?.length > 9
                    ? `${item?.name.substring(0, 9)}...`
                    : item?.name}
                </Grid>
              ))}
          </Grid>{" "}
          {openFacility && (
            <>
              <Typography
                style={{
                  color: "#757575",
                  fontFamily: "'Inter', sans-serif",
                  fontSize: "1rem",
                  fontWeight: "400",
                  margin: "1rem 0",
                }}
              >
                {item?.description}
              </Typography>
            </>
          )}
          <Button
            onClick={showMoresHandler}
            sx={{
              color: "#222",
              fontSize: "1rem",
              fontWeight: "400",
              fontFamily: "'Inter', sans-serif",
              padding: 0,
            }}
          >
            More {openFacility ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Button>
          <Typography
            style={{
              color: "#757575",
              fontFamily: "'Inter', sans-serif",
              fontSize: "1rem",
              fontWeight: "400",
              marginTop: "1rem",
            }}
          >
            <span
              style={{
                color: "#303030",
                fontFamily: "'Inter', sans-serif",
                fontSize: "1.2rem",
                fontWeight: "700",
              }}
            >
              LKR {item.minimumCost}
            </span>{" "}
            {type === "hotel" ? "per room/nightly" : "minimum cost"}

          </Typography>
          {type === "hotel" && item?.accommodation?.length > 0 && (
            <Booking profile={item} open={open} setOpen={setOpen} />
          )}
          {(type === "dayout" || type === "honeymoon") && item?.packageType && (
            <OtherBooking profile={item} open={open} setOpen={setOpen} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
