import React from "react";
import "./TermsConditions.css";

function TermsConditions() {
  return (
    <div className="privacy">
      <div className="PrivacyText">
        <h1>Terms and Conditions</h1>
      </div>
      <div className="backPP">
        <div className="mainPharagraphs">
          <span>Last updated: November 19, 2023</span>
          <p>Hello and welcome to Mr traveller!</p>
          <p>
            {" "}
            The Mr traveller Website, situated at https://mr-traveller.com/ , is subject
            to the following terms and conditions.
          </p>
          <p>
            {" "}
            We deem that you have accepted these terms and conditions if you
            access this website. If any of the terms and conditions on this page
            do not agree with you, do not use Mr traveller any further.
          </p>
          <p>
            {" "}
            For purposes of these Terms and Conditions, Privacy Statement, and
            Disclaimer Notice, as well as all Agreements, the following
            terminology is used: Client, You, and You're all refer to the user
            who is in compliance with the terms and conditions of the Company
            when they access this website. We, Ourselves, The Company, and Us
            all refer to our Company. The terms "Party," "Parties," or "Us"
            include both the Client and ourselves. All terms refer to the
            provision of our assistance to the client in the most effective
            manner for the specific purpose of addressing that client's needs in
            relation to the rendering of the Company's stated services, in
            accordance with and subject to, applicable law of the Netherlands.
            It is assumed that any use of the aforementioned terminology or
            other words in the singular, plural, capitalization, and/or
            he/she/they refers to the same.
          </p>
          <h4>License</h4>
          <p>
            The intellectual property rights of all of the content on Mr traveller,
            unless otherwise noted, are owned by Mr traveller Systems and/or
            its licensors. All rights to intellectual property are reserved. In
            accordance with the limitations outlined in these terms and
            conditions, you may access this from Mr traveller for your own personal
            use. Not allowed are
          </p>
          <ul>
            <li>Republish material from Mr traveller</li>
            <li>Sell, rent, or sub-license material from Mr traveller</li>
            <li>Redistribute content from Mr traveller</li>
            <li>Reproduce, duplicate, or copy material from Mr traveller</li>
          </ul>
          <p>
            This Agreement begins on the date of this Agreement. Our General
            Terms and Conditions were created using the General Terms and
            Conditions Template. Portions of this website provide users with the
            opportunity to post and exchange opinions and information in certain
            areas of the website. Mr traveller Systems does not filter,
            edit, publish or review comments before they appear on the site.
            Comments do not reflect the views or opinions of Mr traveller
            Systems, its agents and/or affiliates. Comments reflect the views
            and opinions of the person who posted them. To the fullest extent
            permitted by law, Mr traveller Systems will not be liable for
            any liability, damages or costs incurred and/or incurred as a result
            of the Comments or the use, posting and/or display of the Comments.
            shall not be held liable. this website. Mr traveller Systems
            reserves the right to monitor all comments and remove any comments
            deemed inappropriate, offensive, or in violation of these Terms of
            Use. You warrant and represent that:
          </p>
          <ul>
            <li>
              You are entitled to post the Comments on our website and have all
              necessary licenses and consents to do so;
            </li>
            <li>
              The Comments do not invade any intellectual property right,
              including without limitation copyright, patent, or trademark of
              any third party;
            </li>
            <li>
              The Comments do not contain any defamatory, libelous, offensive,
              indecent, or otherwise unlawful material which is an invasion of
              privacy
            </li>
            <li>
              The Comments will not be used to solicit or promote business or
              custom or present commercial activities or unlawful activity.
            </li>
          </ul>
          <h4>Cookies</h4>
          <p>
            We use cookies in our operations. You consented to the usage of
            cookies in accordance with the Mr traveller Privacy Policy by accessing
            Mr traveller. Cookies are used by the majority of interactive
            websites to allow us to retrieve user information for each visit.
            Our website uses cookies to enable the functionality of some areas
            so that users can navigate it more easily. Cookies may also be used
            by some of our affiliate and advertising partners.
          </p>
          <h4>Content Liability</h4>
          <p>
            We are not responsible for the content displayed on your website.
            You agree to indemnify and defend us against any claims made on your
            website. Post links on the Website that could be construed as
            defamatory, obscene, or criminal, or that violate, otherwise
            violate, or facilitate the infringement or other violation of the
            rights of any third party; not. .
          </p>
          <h4>iFrames</h4>
          <p>
            Without prior approval and written permission, you may not create
            frames around our Webpages that alter in any way the visual
            presentation or appearance of our Website.
          </p>
          <h4>Hyperlinking to our Content</h4>
          <p>
            The following organizations may link to our Website without prior
            written approval:
          </p>
          <ul>
            <li>Government agencies;</li>
            <li>Search engines;</li>
            <li>News organizations;</li>
            <li>
              Online directory distributors may link to our Website in the same
              manner as they hyperlink to the Websites of other listed
              businesses; and
            </li>
            <li>
              System-wide Accredited Businesses except soliciting non-profit
              organizations, charity shopping malls, and charity fundraising
              groups which may not hyperlink to our Web site. These
              organizations may link to our home page, to publications or to
              other Website information so long as the link:
            </li>
          </ul>
          <p>
            (a) is not in any way deceptive;
            <br />
            (b) does not falsely imply sponsorship, endorsement, or approval of
            the linking party and its products and/or services; and <br />
            (c) fits within the context of the linking party's site. We may
            consider and approve other link requests from the following types of
            organizations:
          </p>
          <ul>
            <li>
              commonly-known consumer and/or business information sources;
            </li>
            <li>dot.com community sites;</li>
            <li>associations or other groups representing charities;</li>
            <li>online directory distributors;</li>
            <li>internet portals;</li>
            <li>accounting, law, and consulting firms; and</li>
            <li>educational institutions and trade associations.</li>
          </ul>
          <p>
            We will approve link requests from these organizations if we decide
            that:
            <br />
            (a) the link would not make us look unfavorably to ourselves or to
            our accredited businesses; (b) the organization does not have any
            negative records with us; (c) the benefit to us from the visibility
            of the hyperlink compensates the absence of Mr traveller; and
            (d) the link is in the context of general resource information.{" "}
            <br />
            These organizations may link to our home page so long as the link:
            (a) is not in any way deceptive; (b) does not falsely imply
            sponsorship, endorsement, or approval of the linking party and its
            products or services; and (c) fits within the context of the linking
            party's site. <br />
            If you are one of the organizations listed in paragraph 2 above and
            are interested in linking to our website, you must inform us by
            sending an e-mail to Mr traveller. Please include your name,
            your organization name, and contact information as well as the URL
            of your site, a list of any URLs from which you intend to link to
            our Website, and a list of the URLs on our site to which you would
            like to link. Wait 2-3 weeks for a response. Approved organizations
            may link to our website as follows:
            <br />
          </p>
          <ul>
            <li>When using our company name. again</li>
            <li>Use a linked integrated resource locator. again</li>
            <li>
              Use other descriptions of our Linked Sites that are meaningful in
              the context and format of the content on the Linking Party's site.
              You may not use Mr traveller Systems logos or other graphics
              in your links without a trademark license agreement.
            </li>
          </ul>
          <h4>Content Liability</h4>
          <p>
            We are not responsible for the content displayed on your website.
            You agree to indemnify and defend us against any claims made on your
            website. Post links on the Website that could be construed as
            defamatory, obscene, or criminal, or that violate, otherwise
            violate, or facilitate the infringement or other violation of the
            rights of any third party; not.
          </p>
          <h4>Your Privacy</h4>
          <p>Please read Privacy Policy</p>
          <h4>Reservation of Rights</h4>
          <p>
            We reserve the right to request the removal of all links or any
            particular link to our website. You agree to immediately remove any
            link to our Site upon request. We also reserve the right to change
            these Terms of Use and its linking policy at any time. By continuing
            to link to our site, you agree to be bound by and comply with these
            linking terms.{" "}
          </p>
          <h4>Removal of links from our website</h4>
          <p>
            If for any reason you find an objectionable link on our website,
            please contact us at any time and let us know. We will consider
            requests to remove links, but we are under no obligation to respond
            directly to you.
          </p>
          <p>
            We do not guarantee the accuracy of the information on this website
            and do not guarantee its completeness or accuracy. We also do not
            guarantee that the website will continue to be available or that the
            content on the website will be kept up to date.
          </p>
          <h4>Disclaimer</h4>
          <p>
            To the maximum extent permitted by applicable law, we exclude all
            representations, warranties, and conditions relating to our website
            and the use of this website. Nothing in this disclaimer will:
          </p>
          <ul>
            <li>
              limit or exclude our or your liability for death or personal
              injury;
            </li>
            <li>
              limit or exclude our or your liability for fraud or fraudulent
              misrepresentation;
            </li>
            <li>
              limit any of our or your liabilities in any way that is not
              permitted under applicable law; or
            </li>
            <li>
              exclude any of our or your liabilities that may not be excluded
              under applicable law.
            </li>
          </ul>
          <p>
            The limitations and prohibitions in this section and elsewhere in
            this disclaimer are:
          </p>
          <p>
            (a) subject to the preceding paragraph; (b) manage all liability
            arising from the disclaimers, including contract liability, tort and
            breach of statutory duty;
          </p>
          <p>
            As long as the website and the information and services on the
            website are available free of charge, we will not be liable for any
            loss or damage of any kind.
          </p>
          <h5 style={{ lineHeight: "1.2rem" }}>
            Mr traveller <br />
            Attention: Privacy Compliance Officer <br />
            349/F,
            <br />
            Batagama North, <br />
            Ja-Ela. <br />
            076 197 3176 <br />
            <span>mrtravelersinfo@gmail.com</span>
          </h5>
        </div>
      </div>
    </div>
  );
}

export default TermsConditions;
